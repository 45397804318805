import './Style.scss';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { DashboardInterface } from 'Interfaces/DashBoardInterface';
import { StateInterface } from 'Interfaces/StateInterface';
import { ChartModel } from 'Interfaces/ChartInterface';
import { ConfirmationModalProps } from 'views/Modals/ConfirmationModal';
import { ModalComponentNames, ModalInterface } from 'Interfaces/ModalInterface';
import * as React from 'react';
import ReactGridLayout, { Responsive, WidthProvider  } from "react-grid-layout";
import { Button, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getLocalization } from 'global/global';
import { deleteDashboard, updateLayout } from 'actions/dashboardActions';
import { useAppDispatch } from 'index';
import { navigateAddModal, navigateRemoveModal } from 'actions/navigationAction';
import DashboardChart from './DashboardChart';
import { AddChartToDashboardModalProps } from './AddChartToDashboardModal';

const ResponsiveGridLayout = WidthProvider(Responsive);

export default function Dashboard() {
  const dispatch = useAppDispatch();
  const dashboard = useSelector((state: StateInterface) => state.dashboard);
  const charts = useSelector((state: StateInterface) => state.chartsMenu.charts);
  const dashboardMenu = useSelector((state: StateInterface) => state.dashboardMenu);

  const [selectedDashboard, setSelectedDashboard] = React.useState<DashboardInterface | undefined>(undefined);
  const [dashboardCharts, setDashboardCharts] = React.useState<ChartModel[]>([]);
  const [layout, setLayout] = React.useState<ReactGridLayout.Layout[]>([]);
  const [applyFilters, setApplyFilters] = React.useState(false);

  React.useEffect(() => {
    if (dashboardMenu.active) {
      const selected = dashboard.collection.find(d => `${d.id}` === dashboardMenu.active);
      setSelectedDashboard(selected);
    } else {
      setSelectedDashboard(undefined);
    }
  }, [dashboardMenu.active, dashboard]);

  React.useEffect(() => {
    const chart = charts.filter(c => selectedDashboard?.chartIds?.includes(Number(c.id)));
    if (selectedDashboard) {
      if (selectedDashboard.layout) {
        setLayout(selectedDashboard.layout);
      } else {
        const lo = chart.map((c, i) => {
          return {
            i: `${c.id}`, x: (i % 2) * 2, y: Math.floor( i / 2), w: 1, h: 4
          };
        });
        setLayout(lo);
        void dispatch(updateLayout({ dashboardId: selectedDashboard.id, layout: JSON.stringify(lo)}));
      }
    }
    setDashboardCharts(chart);
  }, [selectedDashboard, charts]);

  const resetLayout = () => {
    if (selectedDashboard) {
      const chart = charts.filter(c => selectedDashboard?.chartIds?.includes(Number(c.id)));
      const lo = chart.map((c, i) => {
        return {
          i: `${c.id}`, x: (i % 2) * 2, y: Math.floor( i / 2), w: 1, h: 4
        };
      });
      setLayout(lo);
      void dispatch(updateLayout({ dashboardId: selectedDashboard.id, layout: JSON.stringify(lo)}));
    }
  };

  const addChart = () => {
    if (selectedDashboard) {
      const modalProps: ModalInterface<AddChartToDashboardModalProps> = {
        component: ModalComponentNames.AddChartToDashboardModal,
        props: {
          onClose: () => {
            dispatch(navigateRemoveModal(ModalComponentNames.AddChartToDashboardModal));
          },
          dashboardId: selectedDashboard.id,
          currentCharts: selectedDashboard.chartIds || []
        }
      };
      dispatch(navigateAddModal(modalProps));
    }
  };

  const onResizeStop = (layout: ReactGridLayout.Layout[], oldItem, newItem,
    placeholder, e: MouseEvent, element: HTMLElement) => {
    console.log(element);
  };

  const onUpdateLayout = (layout) => {
    if (selectedDashboard?.id) {
      void dispatch(updateLayout({ dashboardId: selectedDashboard.id, layout: JSON.stringify(layout)}));
      setLayout(layout);
    }
  };

  const onRemoveDashboard = () => {
    const modalProps: ModalInterface<ConfirmationModalProps> = {
      component: ModalComponentNames.ConfirmationModal,
      props: {
        onClose: () => {
          dispatch(navigateRemoveModal(ModalComponentNames.ConfirmationModal));
        },
        onConfirm: () => {
          if (selectedDashboard) {
            void dispatch(deleteDashboard({
              dashboardId: selectedDashboard?.id,
            }));
          }
          dispatch(navigateRemoveModal(ModalComponentNames.ConfirmationModal));
        },
        localizations: {
          cancel: getLocalization('cancel'),
          confirm: getLocalization('remove'),
          confirmStyle: 'danger',
          header: (<label>{getLocalization('confirm')}</label>),
          body: (<p>{getLocalization('deleteDashboard')}</p>)
        }
      }
    };
    dispatch(navigateAddModal(modalProps));
  };

  return dashboardMenu.active ? (
    <div className="dashboard-container">
      <Row className="dashboard-container">
        <h5 className="mr-3 dashboard-name">{selectedDashboard?.name}</h5>
        <Button
          variant="primary"
          size="sm"
          onClick={addChart}
          disabled={dashboardCharts?.length >= 10}
        >
          <i className="fa fa-plus" aria-hidden="true" />
          <span className="add-chart-span">{getLocalization('addChart')}</span>
        </Button>
        <Button
          size="sm"
          variant={applyFilters ? 'success' : 'secondary'}
          onClick={() => setApplyFilters(!applyFilters)}
        >
          <i className="fa fa-refresh"/>
          <span className="add-chart-span">{getLocalization('apply_filters')}</span>
        </Button>
        <Button
          size="sm"
          variant={'success'}
          onClick={() => resetLayout()}
        >
          <i className="fa fa-refresh"/>
          <span className="add-chart-span">{getLocalization('resetLayout')}</span>
        </Button>
        <Button
          size="sm"
          onClick={onRemoveDashboard}
          variant={'danger'}
        >
          <i className="fa fa-trash"/>
        </Button>
      </Row>
      <div className="dashboard-container">
        <ResponsiveGridLayout
          cols={{ lg: 2, md: 2, sm: 1, xs: 1, xxs: 1 }}
          layouts={{lg: layout}}
          breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
          rowHeight={130}
          isResizable={false}
          onLayoutChange={(layout: ReactGridLayout.Layout[]) => {
            console.log(layout);
            onUpdateLayout(layout);
          }}
          onResizeStop={onResizeStop}
        >
          {dashboardCharts && selectedDashboard ? (
            dashboardCharts?.map(chart => {
              // return ();
              return (
                <div key={`${chart.id}`}>
                  <DashboardChart
                    chart={chart}
                    dashboardId={selectedDashboard.id}
                    layout={layout}
                    applyFilters={applyFilters}
                  />
                </div>
              );
            })
          ) : null}
        </ResponsiveGridLayout>
      </div>
    </div>
  ) : (
    <p>{getLocalization('selectDashboard')}</p>
  );
}
