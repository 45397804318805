import { getLocalization } from 'global/global';
import { useAppSelector } from 'index';
import { ChartModel } from 'Interfaces/ChartInterface';
import { QuestionInterface } from 'Interfaces/Forms/QuestionInterface';
import * as React from 'react';
import { Form } from 'react-bootstrap';
import { uniqueFormsSelector } from 'reducers/formsReducer';
import { getFormUtils } from 'views/SingleInstance/utils/FormUtilsHolder';

interface Props {
  model: ChartModel;
  updateModel: (model: ChartModel) => void;
}

export default function XAxis(props: Props) {
  const { model } = props;
  const barCharts = ['BAR_NUMERIC', 'BAR_CHOICE'];
  const forms = useAppSelector(state => uniqueFormsSelector(state));
  const locationLabels = useAppSelector(state => state.clientPersist.locationLabels);

  const [xAxisQuestions, setXAxisQuestions] = React.useState<Pick<QuestionInterface, 'id' | 'text'>[]>([]);
  const [noHierarchy, setNoHierarchy] = React.useState(true);

  React.useEffect(() => {
    const keys = Object.keys(model.forms);
    const selectedForms = Array.from(new Set(forms.filter(f => keys.indexOf(f.ref) > -1)));
    const isBarCharts = barCharts.indexOf(`${model.type}`) !== -1;
    if (isBarCharts) {
      const noHierarchy = selectedForms.some((f) =>
        !((f.type === 'POI' || (f.type === 'TASKFORM' && !f.isChild))
      && f.hasLocationHierarchy));
      setNoHierarchy(noHierarchy);
    }
    if (selectedForms.length === 1) {
      const formUtils = getFormUtils(selectedForms[0]);
      const xAQ: Pick<QuestionInterface, 'id' | 'text'>[] = [];
      const qns = formUtils.getQuestions();
      const qnKeys = Object.keys(qns);
      for (const key of qnKeys) {
        const qn = qns[key];
        if (qn.inVisible) {
          continue;
        }
        switch (qn.type) {
          case 'IntQuestion':
          case 'SelectOneQuestion':
          case 'SelectMultipleQuestion':
          case 'BooleanQuestion':
          case 'LikertScaleQuestion':
          case 'StatusQuestion':
            if (isBarCharts) {
              xAQ.push({ text: qn.text, id: qn.id });
            }
            break;
          case 'StringQuestion':
            if (qn.validationlist && qn.validationlist.length > 0 && model.type === 'BAR_NUMERIC') {
              xAQ.push({ text: qn.text, id: qn.id });
            }
            break;
          case 'DateQuestion':
            xAQ.push({ text: qn.text, id: qn.id });
            break;
          default:
            break;
        }
      }
      setXAxisQuestions(xAQ);
    } else {
      setXAxisQuestions([]);
    }
  }, [model.forms]);

  const onXAxisChange = (e) => {
    props.updateModel({...model, xaxis: e.target.value});
  };

  const getOptions = React.useCallback(() => {
    const questions = xAxisQuestions.map((q) => (
      <option
        key={`${q.id}-select-option`}
        value={q.id}
      >
        {q.text}
      </option>
    ));
    if (barCharts.indexOf(model.type) > -1) {
      const locations = !noHierarchy ? locationLabels.map((label, index) => {
        return label ? (
          <option key={`location-level-select-option-${index}`} value={`location${index + 1}`}>{label}</option>
        ) : null;
      }) : [];
      return locations.concat(questions).concat([
        <option value="year-when-updated" key="modifiedyear">{getLocalization('yearUpdated')}</option>,
        <option value="year-when-created" key="createdyear">{getLocalization('yearCreated')}</option>,
        <option value="month-when-updated" key="modifiedmonth">{getLocalization('monthUpdated')}</option>,
        <option value="month-when-created" key="createdmonth">{getLocalization('monthCreated')}</option>,
        <option value="quarter-when-updated" key="modifiedquarter">{getLocalization('quarterUpdated')}</option>,
        <option value="quarter-when-created" key="createdquarter">{getLocalization('quarterCreated')}</option>
      ]);
    } else {
      return [
        <option value="created" key="createdday">{getLocalization('createdOn')}</option>,
        <option value="modified" key="updatedday">{getLocalization('lastUpdatedAt')}</option>
      ].concat(questions);
    }
  }, [model.type, model.forms, model.formId, xAxisQuestions, noHierarchy, model.xaxis]);

  return (
    <div className="form-inline chart-x-axis chart-content-spacing col-3">
      <label>{getLocalization('xaxis')}</label>
      <Form.Control
        as="select"
        size="sm"
        value={model.xaxis || ''}
        onChange={onXAxisChange}
        name="legendid"
        /** Roses are red, violets are blue, hacks make the world a better place */
        style={{width: 'calc(100% - 50px)'}}
      >
        <option value="">{getLocalization('selone')}</option>
        {getOptions()}
      </Form.Control>
    </div>
  );
}
