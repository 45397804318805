import { StateInterface } from 'Interfaces/StateInterface';
import { addChartToDashboard } from 'actions/dashboardActions';
import { getLocalization } from 'global/global';
import { useAppDispatch } from 'index';
import * as React from 'react';
import { FormControl, FormGroup, FormLabel } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import GenericModal from 'views/Modals/GenericModal';

export interface AddChartToDashboardModalProps {
  onClose: () => void;
  dashboardId: number;
  currentCharts: number[];
}

export default function AddChartToDashboardModal(props: AddChartToDashboardModalProps) {

  const charts = useSelector((state: StateInterface) => {
    const { forms, chartsMenu } = state;
    const visibleCharts = forms.loaded ? chartsMenu.charts.map((chart) => {
      const form = forms.collection.find(f => chart.forms[f.ref]);
      if (form) {
        return chart;
      }
      return undefined;
    }).filter(chart => chart !== undefined) : chartsMenu.charts;
    return visibleCharts;
  });
  const dispatch = useAppDispatch();
  const [chartId, setChartId] = React.useState('');
  const [validate, setValidate] = React.useState(false);

  const onSave = () => {
    if (chartId === '') {
      setValidate(true);
      return;
    }
    void dispatch(addChartToDashboard({ dashboardId: props.dashboardId, chartId: Number(chartId) }));
    props.onClose();
  };

  return (
    <GenericModal
      visible={true}
      title={getLocalization('addChart')}
      cancel={props.onClose}
      cancelText={getLocalization('cancel')}
      confirmText={getLocalization('add')}
      onConfirm={onSave}
      body={(
        <FormGroup>
          <FormLabel>{getLocalization('addChart')}</FormLabel>
          <FormControl
            as='select'
            value={chartId}
            onChange={(e) => setChartId(e.target.value)}
            size='sm'
            isInvalid={validate && chartId === ''}
          >
            <option value={''}>{getLocalization('selectOne')}</option>
            {charts.map((chart) =>
              !props.currentCharts.includes(Number(chart.id)) &&
              (chart.questions.length === 1 || chart.combine === 'FIELD') ? (
                  <option value={chart.id} key={`dashbord-chart-${props.dashboardId}-${chart.id}`}>
                    {chart.name}
                  </option>
                ) : null
            )}
          </FormControl>
        </FormGroup>
      )}
    />
  );
}
