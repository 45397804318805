import _ from "lodash";
import moment from "moment";
import { ChartModel } from "Interfaces/ChartInterface";
import { DataPoint } from "Interfaces/DataPoint";
import { FormInterface } from "Interfaces/Forms/FormsInterface";
import { QuestionInterface, questionTypes } from "Interfaces/Forms/QuestionInterface";
import { isNullOrUndefined, pad } from "utils/utils";
import { getFormUtils } from "views/SingleInstance/utils/FormUtilsHolder";
import { LocationInterface } from "Interfaces/LocationInterface";

export const processChartData = (
  model: ChartModel, dataPoints: DataPoint[], form: FormInterface, locations: LocationInterface[]
) => {
  const fields = [...model.forms[form.ref].questions];
  const formUtils = getFormUtils(form);
  const chartDataPoints = {};
  const NO_RESPONSE = 'No response';

  const isSingleSelectQuestion = (q: QuestionInterface) => {
    return q.type === questionTypes.SELECT_ONE_QUESTION ||
    q.type === questionTypes.BOOLEAN_QUESTION ||
    q.type === questionTypes.LIKERT_SCALE_QUESTION ||
    q.type === questionTypes.STATUS_QUESTION;
  };

  const isChoiceChart = () => model.type === 'BAR_CHOICE' || model.type === 'LINE_CHOICE';

  const getXText = _.memoize((value: string, qn: QuestionInterface) => {
    if (qn.type === questionTypes.BOOLEAN_QUESTION) {
      if (`${value}` === `true`) {
        return 'TRUE';
      } else if (`${value}` === `false`) {
        return 'FALSE';
      }
    } else {
      const option = qn.listItems?.listItem.find(li => li.id && li.id === value || li.value === value);
      if (option) {
        return option.value;
      }
    }
    return NO_RESPONSE;
  });

  if (!model.xaxis) {
    dataPoints.forEach((dp) => {
      fields.forEach(field => {
        const qn = formUtils.getQuestion(field);
        const value = dp[qn.id];
        if (isChoiceChart()) {
          const seriesName = `${qn.text}`;
          chartDataPoints[qn.text] = chartDataPoints[qn.text] || [];
          if (isNullOrUndefined(value)) {
            chartDataPoints[seriesName][NO_RESPONSE] = chartDataPoints[qn.text][NO_RESPONSE] || [];
            chartDataPoints[seriesName][NO_RESPONSE].push(dp);
          } else {
            const values = `${value}`.split(',');
            for (const val of values) {
              const yText = getXText(val, qn);
              chartDataPoints[seriesName][yText] = chartDataPoints[qn.text][yText] || [];
              chartDataPoints[seriesName][yText].push(dp);
            }
          }
        } else {
          const seriesName = `${formUtils.getModel().name} - ${qn.text}`;
          chartDataPoints[seriesName] = chartDataPoints[seriesName] || [];
          if (!isNullOrUndefined(value)) {
            chartDataPoints[seriesName].push(dp);
          }
        }
      });
    });
  } else {
    let X_AXIS = model.xaxis;
    if (model.xaxis.indexOf('when-updated') > -1) {
      X_AXIS = 'modified';
    } else if (model.xaxis.indexOf('when-created') > -1) {
      X_AXIS = 'created';
    }
    const xQuestion = formUtils.getQuestion(model.xaxis);

    const getLocation = _.memoize((location: number) => {
      const loc = locations.find(l => `${l.key}` === `${location}`);
      if (loc) {
        return loc.title;
      }
      return NO_RESPONSE;
    });

    const getDate = _.memoize((value, scale: 'month' | 'quarter' | 'year' | 'DAY' | 'WEEK' | 'MONTH' | 'YEAR') => {
      if (value) {
        const date = new Date(value);
        if (scale === 'year' || scale === 'YEAR') {
          return `${date.getFullYear()}`;
        } else if (scale === 'month' || scale === 'MONTH') {
          return `${date.getFullYear()}-${pad(date.getMonth() + 1)}`;
        } else if (scale === 'quarter') {
          const month = date.getMonth() + 1;
          return `${date.getFullYear()}-${Math.ceil(month / 3)}`;
        } else if (scale === 'DAY') {
          return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}`;
        } else if (scale === 'WEEK') {
          return `${pad(moment(date).week())}-${date.getFullYear()}`;
        }
      }
      return NO_RESPONSE;
    });

    const isTimeField = () => X_AXIS === 'created' || X_AXIS === 'modified';

    dataPoints.forEach((dp) => {
      fields.forEach(field => {
        const qn = formUtils.getQuestion(field);
        if (!isNullOrUndefined(dp[qn.id]) && !isNullOrUndefined(dp[X_AXIS]) && dp[X_AXIS] !== '') {
          const xValue = dp[X_AXIS];
          let xText = NO_RESPONSE;
          chartDataPoints[`${qn.text}`] = chartDataPoints[`${qn.text}`] || {};
          if (X_AXIS.indexOf('location') > -1) {
            xText = getLocation(xValue);
          } else if (isTimeField()) {
            const scale = model.xaxis?.substring(0, model.xaxis?.indexOf('-'));
            xText = getDate(xValue, scale || model.scale);
          } else if (xQuestion.type === questionTypes.SELECT_MULTIPLE_QUESTION) {
            const values = xValue.split(',');
            for (const v of values) {
              const xtext = getXText(v, xQuestion);
              chartDataPoints[`${qn.text}`][xtext] = chartDataPoints[`${qn.text}`][xtext] || [];
              chartDataPoints[`${qn.text}`][xtext].push(dp);
            }
            return;
          } else if (isSingleSelectQuestion(xQuestion)) {
            xText = getXText(xValue, xQuestion);
          } else if (xQuestion.type === questionTypes.DATE_QUESTION) {
            if (model.scale) {
              // ts-ignore
              xText = getDate(xValue, model.scale);
            }
          } else {
            xText = xValue;
          }
          if (isChoiceChart()) {
            chartDataPoints[`${qn.text}`][xText] = chartDataPoints[qn.text][xText] || {};
            const yValue = dp[qn.id];
            const values = `${yValue}`.split(',');
            for (const value of values) {
              const yText = getXText(value, qn);
              chartDataPoints[`${qn.text}`][xText][yText] = chartDataPoints[qn.text][xText][yText] || [];
              chartDataPoints[`${qn.text}`][xText][yText].push(dp);
            }
          } else {
            const seriesName = `${formUtils.getModel().name} - ${qn.text}`;
            chartDataPoints[seriesName] = chartDataPoints[seriesName] || {};
            chartDataPoints[seriesName][xText] = chartDataPoints[seriesName][xText] || [];
            chartDataPoints[seriesName][xText].push(dp);
          }
        }
      });
    });
  }
  return chartDataPoints;
};
